























































































import { Component, Vue } from "vue-property-decorator";
import SignatureService from "@/services/SignatureService";
import { namespace } from "vuex-class";
import { processError } from "@/utils/ComponentUtils";
import SignerDTO from "@/dto/signature/SignerDTO";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import { SigningStatus } from "@/constants/SignatureConstants";
import DeclineModal from "@/components/signature/DeclineModal.vue";
import { EventBus } from "@/utils/EventBus";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");

@Component({
	components: { EmbeddedFile },
	computed: {
		SigningStatus() {
			return SigningStatus;
		}
	}
})
export default class SignaturePage extends Vue {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	signer: SignerDTO | null = null;
	signatures: Record<string, string> = {};

	$refs!: {
		embeddedFile: EmbeddedFile,
	}

	mounted(): void {
		EventBus.$on('sign', this.addSignature);
		EventBus.$on('decline', this.getSigner);
		this.getSigner();
	}

	beforeDestroy(): void {
		EventBus.$off('sign');
		EventBus.$off('decline');
	}

	addSignature(id: number, url: string): void {
		Vue.set(this.signatures, id, url);
	}

	getSigner(): void {
		this.startLoading();
		SignatureService.getSigner(this.publicId).then(
			resp => {
				this.signer = resp.data;
				this.stopLoading();
			},
			error => {
				if (error.message.includes('404')) {
					this.signer = null;
				} else {
					processError(error, this);
				}
				this.stopLoading();
			}
		)
	}

	nextSignature(): void {
		const top = window.scrollY + this.unsignedLabels[0]?.getBoundingClientRect().bottom - (document.documentElement.clientHeight / 2);
		window.scrollTo({ left: 0, top, behavior: 'smooth' });
	}

	async confirmAndSave(): Promise<void> {
		this.startLoading();
		const payload = new FormData();
		for (const [id, url] of Object.entries(this.signatures)) {
			await fetch(url)
				.then(res => res.blob())
				.then(blob => {
					payload.append('images', blob)
					payload.append('labelsIds', id)
				}).catch(error => {
					processError(error, this);
					this.stopLoading();
				})
		}
		SignatureService.sign(this.publicId, payload).then(
			resp => {
				this.getSigner();
			},
			error => {
				processError(error, this);
				this.stopLoading();
			}
		)
	}

	decline(): void {
		this.$modal.show(
			DeclineModal,
			{},
		);
	}

	goToMainPage(): void {
		this.$router.push({ name: RouteNames.HOME })
	}

	get publicId(): string {
		return this.$route.params.publicId;
	}

	get unsignedLabels(): Array<HTMLButtonElement> | [] {
		return this.$refs.embeddedFile?.$refs?.signaturesLabels
			.filter(label => !(label.id in this.signatures));
	}

}
